export const useContactUs = () => {
  const contactUs = () => {
    const var1 = "contact";
    const var2 = "decal";
    const var3 = "delivery";
    const var4 = "mail";
    const var5 = "to";
    window.location.href = `${var4}${var5}:${var1}@${var2}.${var3}`;
  };
  return contactUs;
};
