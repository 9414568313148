import { DateTime } from "luxon";
import { ObjectType } from "./Types";

export class Utility {
  public static now = () => Math.floor(DateTime.now().toSeconds());
  public static canCompleteDelay = 60 * 7;
  public static truncate = (str: string, amount: number = 16) => {
    if (str.length > amount + 2) {
      return `${str.substring(0, amount)}...`;
    }
    return str;
  };
  public static toSelected = (values: string[]) =>
    values?.reduce((acc, value) => {
      return { ...acc, [value]: true };
    }, {});
}
