import { useDispatch, batch, useSelector } from "react-redux";
import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession,
  ICognitoUserSessionData,
  CognitoRefreshToken,
} from "amazon-cognito-identity-js";
import { getById, post } from "../axios/api";
import { AuthStatus, DocumentType } from "../Enums";
import {
  getJwt,
  setJwt,
  getUser,
  setUser,
  setExpiration,
  getExpiration,
  setIdToken,
  setRefreshToken,
  getRefreshToken,
  getEmail,
  setEmail,
  setVerifyMode,
  getVerifyMode,
} from "../store/authSlice";
import { ObjectType } from "../Types";
import { useCallback, useMemo } from "react";
import { Utility } from "../Utility";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

const poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL || "",
  ClientId: process.env.REACT_APP_USER_POOL_APP_CLIENT || "",
};

const errorCodes: ObjectType = {
  UsernameExistsException: "A user with this email already exists.",
  InvalidParameterException: "Your password needs to be longer than 6 digits.",
  UserNotConfirmedException: "Your email has not been confirmed.",
  UserNotFoundException: "User cannot be found.",
  NotAuthorizedException: "Incorrect credentials.",
  InvalidPasswordException:
    "Your password must be 8 characters or longer, must contain 1 lowercase letter, 1 uppercase letter, 1 number, and 1 special character.",
  LimitExceededException:
    "Too many password reset attempts, please try again later.",
};

const userPool = new CognitoUserPool(poolData);

type LoginType = {
  email: string;
  password: string;
};

type Verify = {
  code: string;
};

type ResendVerificationCodeType = {
  email: string;
};

export const useAuth = () => {
  const dispatch = useDispatch();
  const jwt = useSelector(getJwt);
  const refreshToken = useSelector(getRefreshToken);
  const expiration = useSelector(getExpiration);
  const email = useSelector(getEmail);
  const user = useSelector(getUser);
  const verifyMode = useSelector(getVerifyMode);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const login = ({ email, password }: LoginType) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: userPool,
      });
      const authenticationDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      });
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async (result) => {
          batch(() => {
            dispatch(setEmail(email));
            dispatch(setJwt(result.getAccessToken().getJwtToken()));
            dispatch(setExpiration(result.getAccessToken().getExpiration()));
            dispatch(setIdToken(result.getIdToken().getJwtToken()));
            dispatch(setRefreshToken(result.getRefreshToken().getToken()));
          });

          const userId = result.getIdToken().payload.sub;
          const user = await getById({
            documentType: DocumentType.ACCOUNT,
            id: userId,
          });
          batch(() => {
            dispatch(setUser(user));
          });

          resolve({ type: AuthStatus.SUCCESS });
        },
        onFailure: (err) => {
          resolve({
            type: AuthStatus.ERROR,
            message: errorCodes[err.name],
          });
        },
      });
    }) as ObjectType;
  };

  const signUp = ({ email, password }: ObjectType) => {
    return new Promise((resolve) => {
      userPool.signUp(email, password, [], [], async (err, result) => {
        if (err) {
          resolve({
            type: AuthStatus.ERROR,
            message: errorCodes[err.name],
          });
        } else {
          dispatch(setVerifyMode("account"));
          dispatch(setEmail(email));
          resolve({
            type: AuthStatus.SUCCESS,
          });
        }
      });
    }) as ObjectType;
  };

  const verify = (data: Verify, password?: string) => {
    return new Promise((resolve) => {
      if (!email) {
        resolve({
          type: AuthStatus.ERROR,
          message: errorCodes["UserNotFoundException"],
        });
        return;
      }
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: userPool,
      });
      if (verifyMode === "account") {
        cognitoUser.confirmRegistration(data.code, true, (err, result) => {
          if (err) {
            resolve({ type: AuthStatus.ERROR, message: errorCodes[err.name] });
            return;
          }
          // notification(`Account confirmed!`, "success", 4);
          resolve({ type: AuthStatus.SUCCESS });
        });
      }
      if (verifyMode === "password" && password) {
        cognitoUser.confirmPassword(data.code, password, {
          onSuccess: (result) => {
            resolve({ type: AuthStatus.SUCCESS });
          },
          onFailure: (err) => {
            resolve({ type: AuthStatus.ERROR, message: errorCodes[err.name] });
          },
        });
      }
    });
  };

  const resendVerificationCode = ({ email }: ResendVerificationCodeType) =>
    new Promise((resolve) => {
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: userPool,
      });
      cognitoUser.resendConfirmationCode((err, result) => {
        if (err) {
          resolve({ type: AuthStatus.ERROR, message: err.message });
        } else {
          resolve({ type: AuthStatus.SUCCESS });
        }
        // notification(`A verification code has been sent to ${user.getUsername()}`, "success", 4);
      });
    });

  const logout = async () => {
    const cognitoUser = new CognitoUser({
      Username: email as string,
      Pool: userPool,
    });
    await cognitoUser.signOut();
    dispatch(setJwt(null));
    dispatch(setExpiration(null));
    dispatch(setRefreshToken(null));
    dispatch(setUser(null));
  };

  const forgotPassword = useCallback(
    (username: string) =>
      new Promise((resolve) => {
        if (!username) {
          // console.error('no user to send password');
          resolve({
            type: "error",
            message: errorCodes["UserNotFoundException"],
          });
          return;
        }
        const cognitoUser = new CognitoUser({
          Username: username,
          Pool: userPool,
        });
        cognitoUser.forgotPassword({
          onSuccess: function (data) {
            dispatch(setEmail(username));
            dispatch(setVerifyMode("password"));
            // successfully initiated reset password request
            // console.log('CodeDeliveryData from forgotPassword: ', data);
            resolve({
              type: AuthStatus.SUCCESS,
              message:
                "Success!  Instructions on how to reset your password has been sent to your email.",
            });
          },
          onFailure: function (err) {
            resolve({ type: AuthStatus.ERROR, message: errorCodes[err.name] });
            // console.error('forgotPassword error: ', err);
          },
        });
      }),
    [dispatch]
  );

  const monitorRefreshToken = useCallback(() => {
    if (
      refreshToken &&
      jwt &&
      email &&
      expiration &&
      expiration - 120 < Utility.now()
    ) {
      console.log("expiration: ", expiration);
      navigate("/login");
    }

    // return new Promise((resolve) => {
    //   if (
    //     refreshToken &&
    //     jwt &&
    //     email &&
    //     expiration &&
    //     expiration - 120 < Utility.now()
    //   ) {
    //     console.log("expiration: ", expiration);
    //     const cognitoUser = new CognitoUser({
    //       Username: email,
    //       Pool: userPool,
    //     });
    //     const token = new CognitoRefreshToken({ RefreshToken: refreshToken });
    //     cognitoUser.refreshSession(token, (err, result) => {
    //       if (err) {
    //         console.error(err);
    //         return;
    //       }
    //       batch(() => {
    //         dispatch(setJwt(result.getAccessToken().getJwtToken()));
    //         dispatch(setExpiration(result.getAccessToken().getExpiration()));
    //         dispatch(setRefreshToken(result.getRefreshToken().getToken()));
    //       });
    //     });
    //     setTimeout(() => resolve(true), 500);
    //   } else {
    //     resolve(true);
    //   }
    // });
  }, [email, expiration, jwt, navigate, refreshToken]);

  const isAuthenticated = useMemo(() => {
    return !!jwt && !!expiration && expiration > Utility.now();
  }, [expiration, jwt]);

  const isSuperAdmin =
    user?.id === "7e6b6ab3-ec59-4939-bbc1-5d51edb1ecd1" ||
    user?.id === "b075f736-e2f1-4d05-8098-4ddc133a9c88";

  return {
    login,
    signUp,
    logout,
    forgotPassword,
    monitorRefreshToken,
    isAuthenticated,
    resendVerificationCode,
    user,
    verify,
    isSuperAdmin,
  };
};
