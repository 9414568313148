import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Buffer } from "buffer";
import { v4 } from "uuid";
import { getCartId, setCartId } from "../../store/authSlice";
import { useSelector } from "react-redux";

export default function CartId() {
  const dispatch = useDispatch();
  const cartId = useSelector(getCartId);
  useEffect(() => {
    if (cartId) {
      return;
    }
    axios
      .get("https://geolocation-db.com/json/")
      .then((res) => {
        if (res.data?.IPv4) {
          const ipAddressBuffer = Buffer.from(res.data.IPv4).toString("base64");
          dispatch(setCartId(ipAddressBuffer || v4()));
        } else {
          dispatch(setCartId(v4()));
        }
      })
      .catch(() => {
        dispatch(setCartId(v4()));
      });
  }, [dispatch, cartId]);

  return null;
}
