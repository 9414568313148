import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { makeStyles } from "tss-react/mui";
import { getImage } from "../../axios/image";

const PROCESSED_IMAGE_BUCKET = process.env.REACT_APP_PROCESSED_IMAGE_BUCKET;
const IMAGE_BUFFER_BUCKET = process.env.REACT_APP_IMAGE_BUFFER_BUCKET;

type StyleType = {
  size: number;
  isSquare?: boolean;
  width?: string | number;
  height?: string | number;
  codeBuffer?: string;
};
const useStyles = makeStyles<StyleType>()(
  (theme, { size, isSquare, width, height, codeBuffer }) => ({
    root: {
      // backgroundPosition: "center center",
      // backgroundSize: "contain",
      // backgroundRepeat: "no-repeat",
      // backgroundClip: "content-box",
      width: width || size,
      minWidth: width || size,
      height: height || (isSquare ? size : size / (4 / 3)),
      borderRadius: size <= 48 ? 2 : 4,
      marginTop: codeBuffer ? "auto" : undefined,
      marginBottom: codeBuffer ? "auto" : undefined,
    },
  })
);

type ImageType = {
  filename?: string;
  size: 100 | 192 | 300 | 768;
  height?: string | number;
  width?: string | number;
  className?: string;
  blobUrl?: string;
  codeBuffer?: string;
  imageBuffer?: string;
  file?: any;
  onClick?: () => void;
  isCalculating?: boolean;
  isSquare?: boolean;
  alt?: string;
};
export const Image = ({
  filename,
  size,
  className,
  blobUrl,
  codeBuffer = "",
  imageBuffer = "",
  onClick,
  isSquare = false,
  height,
  width,
  alt,
}: ImageType) => {
  if (codeBuffer || imageBuffer) {
    height = "fit-content";
  }
  const { classes } = useStyles({ size, isSquare, width, height, codeBuffer });
  let filePath;
  const [bufferFilePath, setBufferFilePath] = useState("");

  // const { data, isLoading: isPreviewLoading } = useQuery(
  //   ["image", codeBuffer],
  //   ({ signal }) => ,
  //   {
  //     enabled: !!codeBuffer,
  //     staleTime: Infinity,
  //   }
  // );
  // const imageBuffer = data?.data;
  // const imageSrc = `data:image/png;base64,${imageBuffer}`;

  useEffect(() => {
    const image = async () => {
      const { data } = await getImage(codeBuffer, {});
      const imageSrc = `data:image/png;base64,${data}`;
      setBufferFilePath(imageSrc);
    };

    if (codeBuffer) {
      image();
    }
  }, [codeBuffer]);

  if (imageBuffer) {
    filePath = `data:image/png;base64,${imageBuffer}`;
  } else if (blobUrl) {
    filePath = blobUrl;
  } else if (filename) {
    filePath = `${PROCESSED_IMAGE_BUCKET}/${filename}/${size}.webp`;
  }

  // else if (codeBuffer) {
  //   filePath = imageSrc;
  // }
  if (!filePath && !bufferFilePath) {
    return <></>;
  }
  return (
    // <div
    //   className={`${className} ${classes.root} }`}
    //   style={{ backgroundImage: `url(${filePath})` }}
    //   onClick={onClick}
    // />
    <img
      src={filePath || bufferFilePath}
      alt={alt}
      className={`${className} ${classes.root}`}
    />
  );
};

export default Image;
