import { lazy } from "react";
import { ModuleLayout } from "../Enums";

const AdminListings = lazy(() => import("./AdminListings"));
const Checkout = lazy(() => import("./Checkout"));
const Contact = lazy(() => import("./Contact"));
const CreatorDashboard = lazy(() => import("./CreatorDashboard"));
const CustomerOrders = lazy(() => import("./CustomerOrders"));
const CustomText = lazy(() => import("./CustomText"));
const Decode = lazy(() => import("./Decode"));
const Design = lazy(() => import("./Design"));
const Home = lazy(() => import("./Home"));
const Listings = lazy(() => import("./Listings"));
const Login = lazy(() => import("./Login"));
const Messages = lazy(() => import("./Messages"));
const OrderConfirmation = lazy(() => import("./OrderConfirmation"));
const PrivacyPolicy = lazy(() => import("./PrivacyPolicy"));
const Product = lazy(() => import("./Product"));
const Profile = lazy(() => import("./Profile"));
const Referral = lazy(() => import("./Referral"));
const ReferralRewards = lazy(() => import("./ReferralRewards"));
const ReturnPolicy = lazy(() => import("./ReturnPolicy"));
const SellDesigns = lazy(() => import("./SellDesigns"));
const SignUp = lazy(() => import("./SignUp"));
const ShippingPolicy = lazy(() => import("./ShippingPolicy"));
const ShopOrders = lazy(() => import("./ShopOrders"));
const ShopSettings = lazy(() => import("./ShopSettings"));
const Store = lazy(() => import("./Store"));
const StoreOrders = lazy(() => import("./StoreOrders"));
const StoreSettings = lazy(() => import("./StoreSettings"));
const TermsOfService = lazy(() => import("./TermsOfService"));
const Verify = lazy(() => import("./Verify"));

export const Pages = [
  {
    path: "/",
    component: Home,
    layout: ModuleLayout.PUBLIC_HEADER,
    exact: true,
    scroll: true,
    headerShadow: false,
  },
  {
    path: "/checkout",
    component: Checkout,
    layout: ModuleLayout.PUBLIC_HEADER,
    exact: true,
    scroll: true,
  },
  {
    path: "/decode",
    component: Decode,
    layout: ModuleLayout.WITHOUT_HEADER,
    exact: true,
    scroll: true,
  },

  {
    path: "/order-confirmation/:checkoutSessionId",
    component: OrderConfirmation,
    layout: ModuleLayout.PUBLIC_HEADER,
    exact: true,
    scroll: true,
  },
  {
    path: "/product/:id",
    component: Product,
    layout: ModuleLayout.PUBLIC_HEADER,
    footer: true,
  },
  {
    path: "/custom-text",
    component: CustomText,
    layout: ModuleLayout.PUBLIC_HEADER,
    footer: true,
  },
  {
    path: "/custom-design",
    component: Design,
    layout: ModuleLayout.PUBLIC_HEADER,
    footer: true,
  },
  {
    path: "/custom-text/:dText",
    component: CustomText,
    layout: ModuleLayout.PUBLIC_HEADER,
    footer: true,
  },
  {
    path: "/store/:id",
    component: Store,
    layout: ModuleLayout.PUBLIC_HEADER,
  },
  {
    path: "/contact",
    component: Contact,
    layout: ModuleLayout.PUBLIC_HEADER,
    footer: true,
  },
  {
    path: "/sell-designs",
    component: SellDesigns,
    layout: ModuleLayout.PUBLIC_HEADER,
    footer: true,
  },
  {
    path: "/cashback",
    component: Referral,
    layout: ModuleLayout.PUBLIC_HEADER,
    footer: true,
  },
  {
    path: "/shipping-policy",
    component: ShippingPolicy,
    layout: ModuleLayout.PUBLIC_HEADER,
    footer: true,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
    layout: ModuleLayout.PUBLIC_HEADER,
    footer: true,
  },
  {
    path: "/return-policy",
    component: ReturnPolicy,
    layout: ModuleLayout.PUBLIC_HEADER,
    footer: true,
  },
  {
    path: "/terms-of-service",
    component: TermsOfService,
    layout: ModuleLayout.PUBLIC_HEADER,
    footer: true,
  },
  {
    path: "/creator-dashboard/profile",
    component: Profile,
    layout: ModuleLayout.CREATOR_DASHBOARD,
    private: true,
  },
  {
    path: "/creator-dashboard/customer-orders",
    component: CustomerOrders,
    layout: ModuleLayout.CREATOR_DASHBOARD,
    private: true,
  },
  {
    path: "/creator-dashboard/referral-rewards",
    component: ReferralRewards,
    layout: ModuleLayout.CREATOR_DASHBOARD,
    private: true,
  },
  {
    path: "/creator-dashboard/dashboard",
    component: CreatorDashboard,
    layout: ModuleLayout.CREATOR_DASHBOARD,
    private: true,
  },
  {
    path: "/creator-dashboard/messages",
    component: Messages,
    layout: ModuleLayout.CREATOR_DASHBOARD,
    private: true,
  },
  {
    path: "/creator-dashboard/listings",
    component: Listings,
    layout: ModuleLayout.CREATOR_DASHBOARD,
    private: true,
  },
  {
    path: "/creator-dashboard/store-orders",
    component: StoreOrders,
    layout: ModuleLayout.CREATOR_DASHBOARD,
    private: true,
  },
  {
    path: "/creator-dashboard/store-settings",
    component: StoreSettings,
    layout: ModuleLayout.CREATOR_DASHBOARD,
    private: true,
  },
  {
    path: "/creator-dashboard/shop-orders",
    component: ShopOrders,
    layout: ModuleLayout.CREATOR_DASHBOARD,
    private: true,
  },
  {
    path: "/creator-dashboard/shop-settings",
    component: ShopSettings,
    layout: ModuleLayout.CREATOR_DASHBOARD,
    private: true,
  },
  {
    path: "/creator-dashboard/admin-listings",
    component: AdminListings,
    layout: ModuleLayout.CREATOR_DASHBOARD,
    private: true,
  },
  {
    path: "/signup",
    component: SignUp,
    layout: ModuleLayout.PUBLIC_HEADER,
    exact: true,
    scroll: true,
  },
  {
    path: "/login",
    component: Login,
    layout: ModuleLayout.PUBLIC_HEADER,
    exact: true,
    scroll: true,
  },
  {
    path: "/verify",
    component: Verify,
    layout: ModuleLayout.PUBLIC_HEADER,
    exact: true,
    scroll: true,
  },
  {
    path: "/r/:referralCode",
    component: Home,
    layout: ModuleLayout.PUBLIC_HEADER,
  },
  {
    path: "/:categoryId",
    component: Home,
    layout: ModuleLayout.PUBLIC_HEADER,
  },
];
